import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { StockBalanceItem } from './stock-balance-item-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { MatChipsData } from './mat-chips-data-model';
import { isNilty } from '@core/utils.service';

@JsonObject('StockBalance')
export class StockBalance extends BaseObject {
  @JsonProperty('product', Object, true) product: any = undefined;
  @JsonProperty('lpAvailableQuantity', Number, true) lpAvailableQuantity: number = undefined;
  @JsonProperty('lpPhysicalQuantity', Number, true) lpPhysicalQuantity: number = undefined;
  @JsonProperty('brandonAvailableQuantity', Number, true) brandonAvailableQuantity: number = undefined;
  @JsonProperty('brandonPhysicalQuantity', Number, true) brandonPhysicalQuantity: number = undefined;
  @JsonProperty('pendingQuantity', Number, true) pendingQuantity: number = undefined;
  @JsonProperty('items', [StockBalanceItem], true) items: StockBalanceItem[] = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('isConsistent', Boolean, true) isConsistent: boolean = undefined;
}

@JsonObject('StockBalanceHeader')
export class StockBalanceHeader {
  @JsonProperty('totalAvailableQuantity', Number, true) totalAvailableQuantity: number = undefined;
  @JsonProperty('totalPhysicalQuantity', Number, true) totalPhysicalQuantity: number = undefined;
  @JsonProperty('totalAvailableValue', Number, true) totalAvailableValue: number = undefined;
  @JsonProperty('totalPhysicalValue', Number, true) totalPhysicalValue: number = undefined;
  @JsonProperty('totalPendingQuantity', Number, true) totalPendingQuantity: number = undefined;
}

@JsonObject('StockBalanceResponse')
export class StockBalanceResponse {
  @JsonProperty('header', StockBalanceHeader, true) header: StockBalanceHeader = undefined;
  @JsonProperty('result', [StockBalance], true) stockBalance: StockBalance[] = undefined;
}

@JsonObject('StockBalanceFilters')
export class StockBalanceFilters extends PaginationAndSorting {
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('isConsistent', Boolean, true) isConsistent: boolean = undefined;
  @JsonProperty('supplierCode', String, true) supplierCode: string = undefined;
  @JsonProperty('stockLocationCode', String, true) stockLocationCode: string = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
}
export const stockBalanceFiltersMap = (
  sku?: string,
  isConsistent?: boolean,
  supplierCode?: string,
  stockLocationCode?: string,
  brand?: string,
  category?: string,
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(sku)) {
    map.push(new MatChipsData('Sku', sku));
  }
  if (!isNilty(isConsistent)) {
    map.push(new MatChipsData('Is Consistent', isConsistent.toString()));
  }
  if (!isNilty(supplierCode)) {
    map.push(new MatChipsData('Supplier Code', supplierCode));
  }
  if (!isNilty(stockLocationCode)) {
    map.push(new MatChipsData('Stock Location Code', stockLocationCode));
  }
  if (!isNilty(brand)) {
    map.push(new MatChipsData('Brand', brand));
  }
  if (!isNilty(category)) {
    map.push(new MatChipsData('Category', category));
  }
  return map;
};
