<div class="container-fluid py-4">
  <h4 class="col text-center summary mb-0">Edit tracking info for Operation {{ data.data.operationId }}</h4>

  <div class="row">
    <div class="col-md-12">
      <mat-form-field>
        <input matInput placeholder="Tracking Number" [(ngModel)]="trackingData.trackingNumber" />
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput placeholder="Tracking URL" [(ngModel)]="trackingData.trackingUrl" />
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput placeholder="Carrier" [(ngModel)]="trackingData.carrier" />
      </mat-form-field>
    </div>
  </div>

  <div class="row padding-top-20">
    <div class="col-md-12 text-end">
      <button class="btn-custom" (click)="close()">Cancel</button>
      <button class="btn-custom" [disabled]="!trackingData.trackingUrl || !trackingData.trackingNumber" (click)="save()">Save</button>
    </div>
  </div>
</div>
