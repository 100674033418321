import { NgModule } from '@angular/core';
import { LoaderComponent } from '@core/loader/loader.component';
import { LoaderService } from '@core/loader/loader.service';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { WarehousesService } from './configuration/warehouses/warehouses.service';
import { ContainerComponent } from './container.component';
import { HeaderComponent } from './header/header.component';
import { ItemsListFiltersComponent } from './main-section/items-list/items-list-filters/items-list-filters.component';
import { ItemsListComponent } from './main-section/items-list/items-list.component';
import { ItemsService } from './main-section/items-list/items.service';
import { OperationDetailsAccordionComponent } from './main-section/operations/operation-details/operation-details-accordion/operation-details-accordion.component';
import { OperationDetailsComponent } from './main-section/operations/operation-details/operation-details.component';
import { ShippyproErrorModalComponent } from './main-section/operations/operation-details/shippypro-error-modal/shippypro-error-modal.component';
import { OperationsListFiltersComponent } from './main-section/operations/operations-list/operations-list-filters/operations-list-filters.component';
import { OperationsListComponent } from './main-section/operations/operations-list/operations-list.component';
import { OperationsService } from './main-section/operations/operations.service';
import { PurchaseInvoiceFiltersComponent } from './main-section/purchase-invoice/purchase-invoice-filters/purchase-invoice-filters.component';
import { PurchaseInvoiceItemComponent } from './main-section/purchase-invoice/purchase-invoice-item/purchase-invoice-item.component';
import { PurchaseInvoiceListComponent } from './main-section/purchase-invoice/purchase-invoice-list/purchase-invoice-list.component';
import { PurchaseInvoiceService } from './main-section/purchase-invoice/purchase-invoice.service';
import { ShipmentsListFiltersComponent } from './main-section/shipments/shipments-list-filters/shipments-list-filters.component';
import { ShipmentsListComponent } from './main-section/shipments/shipments-list/shipments-list.component';
import { ShipmentsService } from './main-section/shipments/shipments.service';
import { SpoDetailsComponent } from './main-section/spos/spo-details/spo-details.component';
import { SpoItemsListComponent } from './main-section/spos/spo-items-list/spo-items-list.component';
import { SposListFiltersComponent } from './main-section/spos/spos-list-filters/spos-list-filters.component';
import { SposListComponent } from './main-section/spos/spos-list/spos-list.component';
import { SposService } from './main-section/spos/spos.service';
import { StockBalanceFiltersComponent } from './main-section/stock/stock-balance-filters/stock-balance-filters.component';
import { StockBalanceComponent } from './main-section/stock/stock-balance.component';
import { StockBalanceService } from './main-section/stock/stock-balance.service';
import { StockMovementFiltersComponent } from './main-section/stock/stock-movement/stock-movement-filters/stock-movement-filters.component';
import { StockMovementComponent } from './main-section/stock/stock-movement/stock-movement.component';
import { CreateOperationModalComponent } from './modal/create-operation-modal/create-operation-modal.component';
import { ErrorMessagesModalComponent } from './modal/error-messages-modal.component.ts/error-messages-modal.component';
import { ImportSpoaModalComponent } from './modal/import-spoa-modal/import-spoa-modal.component';
import { ProductAgingInfoModalComponent } from './modal/product-aging-info-modal/product-aging-info-modal.component';
import { ProductAgingComponent } from './main-section/stock/product-aging/product-aging.component';
import { ProductAgingFiltersComponent } from './main-section/stock/product-aging/product-aging-filters/product-aging-filters.component';
import { StockButtonsComponent } from './main-section/stock/stock-buttons/stock-buttons.component';
import { SetTrackingDataModalComponent } from '@container/modal/set-tracking-data-modal/set-tracking-data-modal.component';

@NgModule({
  imports: [SharedModule, AppRoutingModule, ConfigurationModule],
  declarations: [
    HeaderComponent,
    ContainerComponent,
    LoaderComponent,
    SposListComponent,
    SpoItemsListComponent,
    SposListFiltersComponent,
    SpoDetailsComponent,
    ShipmentsListFiltersComponent,
    ShipmentsListComponent,
    ItemsListComponent,
    ItemsListFiltersComponent,
    OperationsListComponent,
    OperationsListFiltersComponent,
    OperationDetailsComponent,
    CreateOperationModalComponent,
    OperationDetailsAccordionComponent,
    StockBalanceComponent,
    StockMovementComponent,
    StockBalanceFiltersComponent,
    StockMovementFiltersComponent,
    PurchaseInvoiceListComponent,
    PurchaseInvoiceItemComponent,
    PurchaseInvoiceFiltersComponent,
    ErrorMessagesModalComponent,
    ShippyproErrorModalComponent,
    ImportSpoaModalComponent,
    ProductAgingInfoModalComponent,
    ProductAgingComponent,
    ProductAgingFiltersComponent,
    StockButtonsComponent,
    SetTrackingDataModalComponent,
  ],
  providers: [
    LoaderService,
    ItemsService,
    SposService,
    OperationsService,
    StockBalanceService,
    WarehousesService,
    ShipmentsService,
    PurchaseInvoiceService,
  ],
})
export class ContainerModule {}
