import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { StockBalanceFilters, stockBalanceFiltersMap } from '@models/stock-balance-model';
import { isNilty } from '@core/utils.service';
import { StockBalanceService } from '../stock-balance.service';
import { CommonsService } from '@shared/commons.service';
import { Supplier } from '@models/supplier-model';
import { SuppliersService } from '@container/configuration/suppliers/suppliers.service';
import { StockLocation } from '@models/stock-location-model';

@Component({
  selector: 'app-stock-balance-filters',
  templateUrl: './stock-balance-filters.component.html',
  styleUrls: ['./stock-balance-filters.component.css'],
})
export class StockBalanceFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<void>();

  public openFilter = false;

  filters: StockBalanceFilters;

  logisticPartners: string[];
  suppliers: Supplier[];
  stockLocation: StockLocation[];

  filtersMap = [];

  constructor(
    private commonService: CommonsService,
    private supplierService: SuppliersService,
    private stockBalanceService: StockBalanceService,
  ) {}

  ngOnInit() {
    this.commonService.getBridgetEnums().subscribe((r) => (this.logisticPartners = r.logisticPartners));

    this.supplierService.getSuppliers().subscribe((resp: Supplier[]) => {
      this.suppliers = resp.sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0));
    });

    this.commonService
      .getStockLocations()
      .subscribe((resp: StockLocation[]) => (this.stockLocation = resp.filter((it) => it.logisticPartner === 'TWS')));

    if (isNilty(this.stockBalanceService.stockBalanceFilters)) {
      this.stockBalanceService.stockBalanceFilters = new StockBalanceFilters();
    }
    this.filters = this.stockBalanceService.stockBalanceFilters;
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    this.stockBalanceService.stockBalanceFilters.pageIndex = 0;
    this.onFilter.next();
  }

  resetFilters() {
    this.filters = new StockBalanceFilters();
    this.stockBalanceService.stockBalanceFilters = this.filters;
    this.onFilter.next();
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = stockBalanceFiltersMap(
      this.filters.sku,
      this.filters.isConsistent,
      this.filters.supplierCode,
      this.filters.stockLocationCode,
      this.filters.brand,
      this.filters.category,
    );
  }
}
