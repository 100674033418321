import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '@core/authorization.service';
import { isNilty } from '@core/utils.service';
import { Operation, OperationFilters } from '@models/operation-model';
import { Permissions } from '@models/permissions';
import { CreateOperationModalComponent } from '../../../modal/create-operation-modal/create-operation-modal.component';
import { OperationsService } from '../operations.service';

@Component({
  selector: 'app-operations-list',
  templateUrl: './operations-list.component.html',
  styleUrls: ['./operations-list.component.css'],
})
export class OperationsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<Operation>;
  displayedColumns = [
    'type',
    'logisticPartner',
    'orderType',
    'client',
    'documentNumber',
    'customerOrderId',
    'supplierCode',
    'warehouse',
    'createdAt',
    'status',
    'updatedAt',
    'feeds',
  ];

  resultsNumber: Observable<number>;

  hasBridgetAdministrationPermission: Observable<boolean>;

  constructor(
    private operationsService: OperationsService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.operationsService.resultsNumber;

    if (isNilty(this.operationsService.operationFilters)) {
      this.operationsService.operationFilters = new OperationFilters();
    }

    this.hasBridgetAdministrationPermission = this.authorizationService.hasBridgetPermission(Permissions.BridgetAdministration);

    this.route.queryParams.subscribe((params) => {
      if (params['documentNumber']) {
        this.operationsService.operationFilters.documentNumber = params['documentNumber'];
      }
    });

    this.paginator.pageSize = this.operationsService.operationFilters.pageSize;
    this.paginator.pageIndex = this.operationsService.operationFilters.pageIndex;
    this.getItems();
  }

  getItems() {
    this.operationsService.getOperationsByFilters().subscribe((resp: Operation[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.operationsService.operationFilters.pageSize = this.paginator.pageSize;
    this.operationsService.operationFilters.pageIndex = this.paginator.pageIndex;
    this.getItems();
  }

  onSort() {
    this.operationsService.operationFilters.sortBy = this.sort.active;
    this.operationsService.operationFilters.sortDirection = this.sort.direction;
    this.getItems();
  }

  applyFilters() {
    this.paginator.pageIndex = 0;
    this.getItems();
  }

  openDetails(op: Operation) {
    this.router.navigate(['operation-detail', op.id]);
  }

  createOperation() {
    this.dialog.open(CreateOperationModalComponent, { disableClose: true });
  }

  navigateToFile(event: MouseEvent, fileLink: string) {
    event.stopPropagation();
    window.open(fileLink);
  }
}
