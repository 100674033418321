import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { PurchaseInvoiceItem } from '@models/purchase-invoice-item-model';
import { PurchaseInvoice, PurchaseInvoiceFilters } from '@models/purchase-invoice-model';
import { Observable } from 'rxjs';
import { PurchaseInvoiceService } from '../purchase-invoice.service';

@Component({
  selector: 'app-purchase-invoice-item',
  templateUrl: './purchase-invoice-item.component.html',
  styleUrls: ['./purchase-invoice-item.component.css'],
})
export class PurchaseInvoiceItemComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  purchaseInvoiceId: string;
  purchaseInvoiceNumber: string;
  pi: PurchaseInvoice;
  initialFilters: PurchaseInvoiceFilters;
  isAuthorized: Observable<boolean>;

  dataSource: MatTableDataSource<PurchaseInvoiceItem>;
  displayedColumns = ['orderNumber', 'lineItemReferenceNumber', 'ean', 'bookTitle', 'quantity', 'price', 'totalPrice', 'taxRate'];

  resultsNumber: Observable<number>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private purchaseInvoiceService: PurchaseInvoiceService,
    private location: Location,
    private resetStatusDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.purchaseInvoiceService.purchaseInvoiceItemResultsNumber;

    if (isNilty(this.purchaseInvoiceService.purchaseInvoiceItemFilters)) {
      this.purchaseInvoiceService.purchaseInvoiceItemFilters = new PurchaseInvoiceFilters();
    }

    this.initialFilters = this.purchaseInvoiceService.purchaseInvoiceItemFilters;

    this.activatedRoute.params.subscribe((param: Params) => {
      this.purchaseInvoiceNumber = param['purchaseInvoiceNumber'];
      this.getPurchaseInvoice();
      this.getPurchaseInvoiceItems();
    });
  }

  loadPage() {
    this.purchaseInvoiceService.purchaseInvoiceItemFilters.pageSize = this.paginator.pageSize;
    this.purchaseInvoiceService.purchaseInvoiceItemFilters.pageIndex = this.paginator.pageIndex;
    this.getPurchaseInvoiceItems();
  }

  onSort() {
    this.purchaseInvoiceService.purchaseInvoiceItemFilters.sortBy = this.sort.active;
    this.purchaseInvoiceService.purchaseInvoiceItemFilters.sortDirection = this.sort.direction;
    this.getPurchaseInvoiceItems();
  }

  applyFilters(filters: PurchaseInvoiceFilters) {
    this.purchaseInvoiceService.purchaseInvoiceItemFilters = filters;
    this.paginator.pageIndex = 0;
    this.getPurchaseInvoiceItems();
  }

  goBack() {
    this.location.back();
  }

  private getPurchaseInvoice() {
    this.purchaseInvoiceService.getPurchaseInvoice(this.purchaseInvoiceNumber).subscribe((resp: PurchaseInvoice) => {
      if (isNilty(resp)) {
        this.goBack();
      }
      this.pi = resp[0];
    });
  }
  private getPurchaseInvoiceItems() {
    this.purchaseInvoiceService.purchaseInvoiceItemFilters.purchaseInvoiceNumber = this.purchaseInvoiceNumber;
    this.purchaseInvoiceService.getPurchaseInvoiceItemsByFilters().subscribe((resp: PurchaseInvoiceItem[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }
}
