<app-stock-balance-filters (onFilter)="applyFilters()"></app-stock-balance-filters>

<div class="container-fluid py-4">
  <h2 class="title-custom">Stock Balance</h2>

  <div class="pt-3 mb-3">
    <app-stock-buttons exclude="stock-balance"></app-stock-buttons>
  </div>

  <div class="row mx-0">
    <div class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6">Total Available Quantity:</b>
        {{ header?.totalAvailableQuantity }}
      </p>
    </div>
    <div class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6"> Total Available Value:</b>
        {{ header?.totalAvailableValue | myCurrency: dataSource.data[0]?.items[0]?.currency }}
      </p>
    </div>
    <div class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6"> Total Physical Quantity:</b>
        {{ header?.totalPhysicalQuantity }}
      </p>
    </div>
    <div class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6"> Total Physical Value:</b>
        {{ header?.totalPhysicalValue | myCurrency: dataSource.data[0]?.items[0]?.currency }}
      </p>
    </div>
    <div class="bluebox">
      <p class="key-table-fixed">
        <b class="margin-right-6"> Total Pending Quantity:</b>
        {{ header?.totalPendingQuantity }}
      </p>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-md-6">
      <button class="btn-custom" (click)="refreshByFilters()">
        <i class="fa fa-refresh margin-right-6" aria-hidden="true"></i>Refresh
      </button>
    </div>
    <div class="col-md-6">
      <mat-paginator
        [length]="resultsNumber | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      ></mat-paginator>
    </div>
  </div>

  <div class="table-container mb-5">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table multirow font-Titillium"
      matSort
      (matSortChange)="onSort()"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="product">Sku</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.product.sku }}
        </td>
      </ng-container>

      <ng-container matColumnDef="logisticPartner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="logisticPartner">Logistic Partner</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.logisticPartner }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lpAvailableQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lpAvailableQuantity">Lp Available Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.lpAvailableQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lpPhysicalQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lpPhysicalQuantity">Lp Physical Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.lpPhysicalQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="brandonAvailableQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="brandonAvailableQuantity">Zion Available Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.brandonAvailableQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="brandonPhysicalQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="brandonPhysicalQuantity">Zion Physical Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.brandonPhysicalQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pendingQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="pendingQuantity">Pending Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.pendingQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reservedQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="reservedQuantity">Reserved Qty</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.lpPhysicalQuantity - sb.lpAvailableQuantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="items">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="items">Items</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.items.length }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">Updated At</th>
        <td mat-cell *matCellDef="let sb">
          {{ sb.updatedAt }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let sb" class="text-end">
          <button
            class="btn-icon"
            aria-label="expand row"
            (click)="expandedElement = expandedElement === sb ? null : sb; $event.stopPropagation()"
          >
            <i class="fa fa-caret-down fa-xl" *ngIf="expandedElement !== sb"></i>
            <i class="fa fa-caret-up" *ngIf="expandedElement === sb"></i>
          </button>
          <button (click)="openStockMovementFiltered(sb.product.sku)" class="btn-icon ms-2">
            <i class="fa fa-history"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let sb" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="sb === expandedElement ? 'expanded' : 'collapsed'">
            <table mat-table [dataSource]="sb.items" class="header-secondary">
              <ng-container matColumnDef="stockLocationCode">
                <th mat-header-cell *matHeaderCellDef>Stock Location</th>
                <td mat-cell *matCellDef="let sbi">
                  {{ sbi.stockLocationCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="availableQuantity">
                <th mat-header-cell *matHeaderCellDef>Available Qty</th>
                <td mat-cell *matCellDef="let sbi">
                  {{ sbi.availableQuantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="physicalQuantity">
                <th mat-header-cell *matHeaderCellDef>Physical Qty</th>
                <td mat-cell *matCellDef="let sbi">
                  {{ sbi.physicalQuantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="supplierCode">
                <th mat-header-cell *matHeaderCellDef>Supplier</th>
                <td mat-cell *matCellDef="let sbi">
                  {{ sbi.supplierCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="purchasePrice">
                <th mat-header-cell *matHeaderCellDef>Purchase Price</th>
                <td mat-cell *matCellDef="let sbi">
                  <span *ngIf="sbi.purchasePrice">{{ sbi.purchasePrice | myCurrency: sbi.currency }} </span>
                </td>
              </ng-container>

              <mat-header-row *matHeaderRowDef="itemsDisplayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let sbi; columns: itemsDisplayedColumns"></mat-row>
            </table>
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let sb; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === sb"
        [class.bg-danger]="!sb.isConsistent"
      ></mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </table>
  </div>
</div>
