import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StockBalanceService } from '@container/main-section/stock/stock-balance.service';
import { isNilty } from '@core/utils.service';
import { ProductAging, ProductAgingDetail, ProductAgingFilters } from '@models/product-aging-model';

@Component({
  selector: 'app-aging-info-modal',
  templateUrl: './product-aging-info-modal.component.html',
  styleUrls: ['./product-aging-info-modal.component.css'],
})
export class ProductAgingInfoModalComponent implements OnInit {
  productAgingList: ProductAging[];
  emptyProductAging = false;

  incomesDataSource: MatTableDataSource<ProductAgingDetail> = new MatTableDataSource<ProductAgingDetail>();
  lastExitDataSource: MatTableDataSource<ProductAgingDetail>;

  displayedColumns = ['operationId', 'quantity', 'date'];

  constructor(
    public dialogRef: MatDialogRef<ProductAgingInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ProductAgingFilters,
    private stockBalanceService: StockBalanceService,
  ) {}

  ngOnInit() {
    this.stockBalanceService.findFilteredProductAging(this.data).subscribe((resp: ProductAging[]) => {
      if (!isNilty(resp)) {
        this.productAgingList = resp;
      } else {
        this.emptyProductAging = true;
      }
    });
  }

  onTabChanged(event: MatTabChangeEvent) {
    const producAging = this.productAgingList[event.index];
    this.loadDetails(producAging);
  }

  loadDetails(pa: ProductAging) {
    this.incomesDataSource = new MatTableDataSource<ProductAgingDetail>(pa.incomesWithoutExit);
    if (!isNilty(pa.lastExit)) {
      this.lastExitDataSource = new MatTableDataSource<ProductAgingDetail>([pa.lastExit]);
    }
  }
}
