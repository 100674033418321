import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SpoFilters, Spo } from '@models/spo-model';
import { SpoItem } from '@models/spo-item-model';

@Injectable()
export class SposService {
  sposFilters: SpoFilters;
  spoItemsFilters: SpoFilters;

  spoResultsNumber = new Subject<number>();
  spoItemResultsNumber = new Subject<number>();

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  getSposByFilters(): Observable<Spo[]> {
    const body = this.converter.fromObjtoJSON(this.sposFilters);
    return this.http
      .post(this.environmentService.getRestEndpoint('spos'), body, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.spoResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
      )
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, Spo));
          } else {
            return [];
          }
        }),
      );
  }

  getSpo(spoId: string): Observable<Spo> {
    return this.http
      .get(this.environmentService.getRestEndpoint('spos') + '/' + spoId, { observe: 'response' })
      .pipe(map((resp: HttpResponse<any>) => resp.body))
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return this.converter.fromJSONtoObj(resp, Spo);
          } else {
            return null;
          }
        }),
      );
  }

  getSpoByOrderId(spoOrderId: string): Observable<Spo> {
    const path = this.environmentService.getRestEndpoint('spos') + '?orderId=' + spoOrderId;
    return this.http
      .get(path, { observe: 'response' })
      .pipe(map((resp: HttpResponse<any>) => resp.body))
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return this.converter.fromJSONtoObj(resp, Spo);
          } else {
            return null;
          }
        }),
      );
  }

  getSpoItemsByFilters(): Observable<SpoItem[]> {
    const body = this.converter.fromObjtoJSON(this.spoItemsFilters);
    return this.http.post(this.environmentService.getRestEndpoint('spoItems'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.spoItemResultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((sposResp: SpoItem[]) => {
        if (!isNilty(sposResp)) {
          return sposResp.map((it) => this.converter.fromJSONtoObj(it, SpoItem));
        } else {
          return [];
        }
      }),
    );
  }

  setStatusSent(spo: Spo): Observable<Spo> {
    const body = this.converter.fromObjtoJSON(spo);
    return this.http.post(this.environmentService.getRestEndpoint('spos') + '/set-status-sent', body).pipe(
      map((resp: any[]) => {
        if (!isNilty(resp)) {
          return this.converter.fromJSONtoObj(resp, Spo);
        } else {
          return null;
        }
      }),
    );
  }

  hasSpoShippingInfo(spoOrderId: string): Observable<boolean> {
    const path = this.environmentService.getRestEndpoint('spos') + '/shipping-info/' + spoOrderId;
    return this.http.get(path, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        return resp.body;
      }),
    );
  }

  deleteSpoShippingInfo(spoOrderId: string): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('spos') + '/delete-shipping-info/' + spoOrderId);
  }
}
