import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isNilty } from '@core/utils.service';
import { MatChipsData } from '@models/mat-chips-data-model';
import { ShipmentFilters, shipmentFiltersMap } from '@models/shipment-model';
import { Status } from '@models/status-mode';
import { Subject } from 'rxjs';
import { ShipmentsService } from '../shipments.service';

@Component({
  selector: 'app-shipments-list-filters',
  templateUrl: './shipments-list-filters.component.html',
  styleUrls: ['./shipments-list-filters.component.css'],
})
export class ShipmentsListFiltersComponent implements OnInit {
  @Input() filters = new ShipmentFilters();
  @Output() onFilter = new Subject<ShipmentFilters>();

  filterForm: FormGroup;
  public openFilter = false;
  filtersMap: MatChipsData[] = [];
  status: Status[] = [];
  clients: string[] = [];

  constructor(private shipmentsService: ShipmentsService) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      shipmentId: new FormControl(),
      createdAt: new FormControl(),
      client: new FormControl(),
      status: new FormControl(),
      carrier: new FormControl(),
      trackingNumber: new FormControl(),
      hasLabel: new FormControl(),
    });

    if (!isNilty(this.filters)) {
      this.filterForm.patchValue(this.filters);
      this.updateFiltersMap();
    }

    this.shipmentsService.getShipmentStatus().subscribe((resp) => {
      this.status = resp;
      this.setStatus();
    });

    this.shipmentsService.getClients().subscribe((resp) => {
      this.clients = resp;
    });
  }

  setStatus() {
    if (this.filters && this.filters.status) {
      this.filterForm.controls['status'].patchValue(this.status.find((it) => it.code === this.filters.status));
      this.updateFiltersMap();
    }
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    this.onFilter.next(this.setShipmentFilter());
  }

  resetFilters() {
    this.filterForm.reset();
    this.updateFiltersMap();
    this.onFilter.next(this.setShipmentFilter());
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  setShipmentFilter(): ShipmentFilters {
    const filters = new ShipmentFilters();
    filters.shipmentId = this.filterForm.value.shipmentId;
    filters.createdAt = this.filterForm.value.createdAt;
    filters.client = this.filterForm.value.client;
    filters.status = !isNilty(this.filterForm.value.status) ? this.filterForm.value.status.code : null;
    filters.carrier = this.filterForm.value.carrier;
    filters.trackingNumber = this.filterForm.value.trackingNumber;
    filters.hasLabel = this.filterForm.value.hasLabel;
    return filters;
  }

  updateFiltersMap() {
    this.filtersMap = shipmentFiltersMap(
      this.filterForm.value.shipmentId,
      this.filterForm.value.createdAt,
      this.filterForm.value.client,
      this.filterForm.value.status,
      this.filterForm.value.carrier,
      this.filterForm.value.trackingNumber,
      this.filterForm.value.hasLabel,
    );
  }
}
