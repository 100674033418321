import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Spo, SpoFilters } from '@models/spo-model';
import { SposService } from '../spos.service';
import { isNilty } from '@core/utils.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { SpoItem } from '@models/spo-item-model';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from '@core/authorization.service';
import { Permissions } from '@models/permissions';
import { DeleteDialogComponent } from '@container/configuration/delete-dialog/delete-dialog-component';

@Component({
  selector: 'app-spo-details',
  templateUrl: './spo-details.component.html',
  styleUrls: ['./spo-details.component.css'],
})
export class SpoDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  spoOrderId: string;
  spo: Spo;
  initialFilters: SpoFilters;
  isAuthorized: Observable<boolean>;
  hasShippingInfo = false;

  dataSource: MatTableDataSource<SpoItem>;
  displayedColumns = [
    'ean',
    'altEan',
    'clientOrderItemId',
    'supplierOrderItemId',
    'supplierOrderItemStatus',
    'price',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'requestedValue',
    'confirmedValue',
    'orderedValue',
  ];

  resultsNumber: Observable<number>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sposService: SposService,
    private location: Location,
    private resetStatusDialog: MatDialog,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.sposService.spoItemResultsNumber;

    if (isNilty(this.sposService.spoItemsFilters)) {
      this.sposService.spoItemsFilters = new SpoFilters();
    }

    this.initialFilters = this.sposService.spoItemsFilters;

    this.paginator.pageSize = this.sposService.spoItemsFilters.pageSize;
    this.paginator.pageIndex = this.sposService.spoItemsFilters.pageIndex;

    this.activatedRoute.queryParams.subscribe((param: Params) => {
      this.spoOrderId = param['orderId'];
      this.getSpoByOrderId();
    });

    this.chekShippingInfoExist();

    this.isAuthorized = this.authorizationService.hasJimboPermission(Permissions.JimboSpoOperation);
  }

  chekShippingInfoExist() {
    this.sposService.hasSpoShippingInfo(this.spoOrderId).subscribe((resp: boolean) => {
      this.hasShippingInfo = resp;
    });
  }

  getSpoByOrderId() {
    this.sposService.getSpoByOrderId(this.spoOrderId).subscribe((resp: Spo) => {
      this.spo = resp;
      this.getSpoItems();
    });
  }

  getSpoItems() {
    this.sposService.spoItemsFilters.spoId = this.spo.id;
    this.sposService.getSpoItemsByFilters().subscribe((resp: SpoItem[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.sposService.spoItemsFilters.pageSize = this.paginator.pageSize;
    this.sposService.spoItemsFilters.pageIndex = this.paginator.pageIndex;
    this.getSpoItems();
  }

  onSort() {
    this.sposService.spoItemsFilters.sortBy = this.sort.active;
    this.sposService.spoItemsFilters.sortDirection = this.sort.direction;
    this.getSpoItems();
  }

  applyFilters(filters: SpoFilters) {
    this.sposService.spoItemsFilters = filters;
    this.paginator.pageIndex = 0;
    this.getSpoItems();
  }

  goBack() {
    this.location.back();
  }

  download(url: string) {
    window.open(url, '_blank');
  }

  setStatusSent() {
    this.resetStatusDialog
      .open(GenericConfirmationModalComponent, {
        data: 'Status will be set to SENT.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.sposService.setStatusSent(this.spo).subscribe((res: Spo) => {
            this.spo = res;
          });
        }
      });
  }

  deleteShippingInfo() {
    this.resetStatusDialog
      .open(DeleteDialogComponent, {
        data: 'Shipping info will be deleted.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.sposService.deleteSpoShippingInfo(this.spoOrderId).subscribe((res: Spo) => {
            this.spo = res;
          });
        }
      });
    this.hasShippingInfo = false;
  }
}
