<div class="container-fluid padding-top-15">
  <mat-expansion-panel class="ship-exp-panel" id="operation-details-accordion">
    <mat-expansion-panel-header class="exp-panel-header">
      <mat-panel-title class="exp-panel-title">
        <div class="row" style="width: 100%">
          <div class="col-md-4 exp-panel-label">
            {{ accordion.headerName }}
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="pack-properties-panel text-light-secondary">
      <div class="row">
        <div class="col-md-3" *ngFor="let field of accordion.fields">
          <p>
            <b>{{ field.label }}: </b> {{ operation | readProperty: field }}
          </p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
