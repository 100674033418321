import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchaseInvoiceService } from '../purchase-invoice.service';
import { PurchaseInvoice, PurchaseInvoiceFilters } from '@models/purchase-invoice-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { isNilty } from '@core/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-invoice-list',
  templateUrl: './purchase-invoice-list.component.html',
  styleUrls: ['./purchase-invoice-list.component.css'],
})
export class PurchaseInvoiceListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<PurchaseInvoice>;
  displayedColumns = [
    'supplierName',
    'purchaseInvoiceNumber',
    'purchaseInvoiceDate',
    'totalQuantity',
    'totalPrice',
    'currency',
    'supplierCode',
  ];

  initialFilters: PurchaseInvoiceFilters;

  resultsNumber: Observable<number>;

  constructor(
    private purchaseInvoiceService: PurchaseInvoiceService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.purchaseInvoiceService.purchaseInvoiceResultsNumber;

    if (isNilty(this.purchaseInvoiceService.purchaseInvoiceFilters)) {
      this.purchaseInvoiceService.purchaseInvoiceFilters = new PurchaseInvoiceFilters();
    }

    this.dataSource = new MatTableDataSource<PurchaseInvoice>([]);
    this.loadPurchaseItemInvoices();
  }

  loadPage() {
    this.purchaseInvoiceService.purchaseInvoiceFilters.pageSize = this.paginator.pageSize;
    this.purchaseInvoiceService.purchaseInvoiceFilters.pageIndex = this.paginator.pageIndex;
    this.loadPurchaseItemInvoices();
  }

  onSort() {
    this.purchaseInvoiceService.purchaseInvoiceFilters.sortBy = this.sort.active;
    this.purchaseInvoiceService.purchaseInvoiceFilters.sortDirection = this.sort.direction;
    this.loadPurchaseItemInvoices();
  }

  applyFilters(filters: PurchaseInvoiceFilters) {
    this.purchaseInvoiceService.purchaseInvoiceFilters = filters;
    this.paginator.pageIndex = 0;
    this.loadPurchaseItemInvoices();
  }

  openDetail(purchaseInvoice: PurchaseInvoice) {
    this.router.navigate(['invoices-detail', purchaseInvoice.purchaseInvoiceNumber]);
  }

  private loadPurchaseItemInvoices() {
    this.purchaseInvoiceService.getPurchaseInvoiceByFilters().subscribe((resp: PurchaseInvoice[]) => {
      this.dataSource.data = resp;
    });
  }
}
