import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ContainerModule } from './container/container.module';
import { ConverterService } from './core/converter.service';
import { AuthorizationService } from './core/authorization.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MyDateAdapter } from './shared/my-date-adapter.component';
import { AppRoutingModule } from './app-routing.module';
import { AppPreloadingStrategy } from './app-loading-strategy';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthenticationService } from './security/authentication.service';
import { EnvironmentServiceProvider } from './environment.service.provider';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, MaterialModule, AppRoutingModule, ContainerModule, ReactiveFormsModule],
  providers: [
    AuthenticationService,
    AppPreloadingStrategy,

    ConverterService,
    EnvironmentServiceProvider,
    AuthorizationService,
    {
      provide: DateAdapter,
      useClass: MyDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS,
    },
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
