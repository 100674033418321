import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Operation } from '@models/operation-model';
import { operationsFieldDefinitions } from '@environments/operations';
import { isNil, isNilty } from '@core/utils.service';
import { GenericConfirmationModalComponent } from '../generic-confirmation-modal/generic-confirmation-modal.component';
import { OperationsService } from '../../main-section/operations/operations.service';
import { BridgetEnums } from '@models/bridget-enums-model';
import { CommonsService } from '@shared/commons.service';

@Component({
  selector: 'app-create-operation-modal',
  templateUrl: './create-operation-modal.component.html',
  styleUrls: ['./create-operation-modal.component.css'],
})
export class CreateOperationModalComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  @ViewChild('operationFile') operationFile: ElementRef;
  currentFileUpload: File;

  completedSteps = [false, false, false, false];
  lastStep = false;

  operation: Operation;
  logisticPartner: string;
  client = 'BRND';
  orderType: string;

  operationTypes: string[];
  logisticPartners: string[];
  clients: string[];
  orderTypes: string[];

  isConsignment = false;
  consignmentExpirationDate: Date;

  constructor(
    public dialogRef: MatDialogRef<CreateOperationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: void,
    private exitDialog: MatDialog,
    private operationsService: OperationsService,
    private commonService: CommonsService,
  ) {}

  ngOnInit() {
    this.operationTypes = operationsFieldDefinitions.map((it) => it.type);
    this.operation = new Operation();

    this.commonService.getBridgetEnums().subscribe((r: BridgetEnums) => {
      this.orderTypes = r.orderTypes;
      this.logisticPartners = r.logisticPartners;
    });
  }

  updatedType() {
    this.completedSteps[0] = !isNilty(this.operation.type);
  }

  updatedData() {
    this.completedSteps[1] = !isNilty(this.logisticPartner) && !isNilty(this.orderType);
  }

  onConsignmentChange(check: boolean) {
    this.isConsignment = check;
    if (!check) {
      this.consignmentExpirationDate = undefined;
    } else {
      this.consignmentExpirationDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    }
    this.updatedData();
  }

  loadFile() {
    this.operationFile.nativeElement.click();
  }

  fileChanged(event) {
    this.currentFileUpload = event.target ? (event.target.files ? event.target.files[0] : undefined) : undefined;
    this.completedSteps[2] = !isNil(this.currentFileUpload);
  }

  confirmFile() {
    this.operation.logisticPartner = this.logisticPartner;
    this.operation.orderType = this.orderType;
    this.operation.client = this.client;
    this.operation.consignmentExpirationDate = this.consignmentExpirationDate ? this.consignmentExpirationDate : null;
    this.lastStep = true;
    this.stepper.next();
  }

  confirmOperation() {
    this.operationsService.newOperation(this.currentFileUpload, this.operation).subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close();
      }
    });
  }

  close() {
    this.exitDialog
      .open(GenericConfirmationModalComponent, {
        data: 'The new operation will be lost.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.dialogRef.close();
        }
      });
  }
}
