import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { StockMovement, StockMovementFilters, StockMovementResponse } from '@models/stock-movement-model';
import { Observable } from 'rxjs';
import { StockBalanceService } from '../stock-balance.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductAgingInfoModalComponent } from '@container/modal/product-aging-info-modal/product-aging-info-modal.component';
import { ProductAgingFilters } from '@models/product-aging-model';

@Component({
  selector: 'app-stock-movement',
  templateUrl: './stock-movement.component.html',
  styleUrls: ['./stock-movement.component.css'],
})
export class StockMovementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  Math: any = Math;
  header: Map<string, number>;
  dataSource: MatTableDataSource<StockMovement>;
  displayedColumns = [
    'operationType',
    'sku',
    'operationId',
    'customerOrderId',
    'orderType',
    'stockLocationCode',
    'receivedQty',
    'shippedQty',
    'totalPurchasePriceAmount',
    'destinationCountry',
    'createdAt',
    'supplierCode',
    'info',
  ];

  resultsNumber: Observable<number>;
  hasFilters = false;

  productAgingFilters: ProductAgingFilters;

  constructor(
    private stockBalanceService: StockBalanceService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.stockBalanceService.stockMovementResultsNumber;
    if (isNilty(this.stockBalanceService.stockMovementFilters)) {
      this.stockBalanceService.stockMovementFilters = new StockMovementFilters();
    }
    this.route.params.subscribe((params) => {
      this.hasFilters = params['hasFilters'];
      this.applyFilters();
    });
    this.dataSource = new MatTableDataSource<StockMovement>([]);
    this.loadStockMovements();
  }

  loadPage() {
    this.stockBalanceService.stockMovementFilters.pageSize = this.paginator.pageSize;
    this.stockBalanceService.stockMovementFilters.pageIndex = this.paginator.pageIndex;
    this.loadStockMovements();
  }

  onSort() {
    this.stockBalanceService.stockMovementFilters.sortBy = this.sort.active;
    this.stockBalanceService.stockMovementFilters.sortDirection = this.sort.direction;
    this.loadStockMovements();
  }

  applyFilters() {
    if (!isNilty(this.stockBalanceService.stockMovementFilters.sku)) {
      this.productAgingFilters = new ProductAgingFilters();
      this.productAgingFilters.sku = this.stockBalanceService.stockMovementFilters.sku;
      this.productAgingFilters.stockLocationCode = this.stockBalanceService.stockMovementFilters.stockLocationCode;
    }
    this.loadStockMovements();
  }

  exportCSV() {
    this.stockBalanceService.exportStockMovement().subscribe();
  }

  openProductAgingInfo(filters: ProductAgingFilters) {
    const data = filters;
    this.dialog.open(ProductAgingInfoModalComponent, { data });
  }

  openProductAgingInfoFromRow(row: StockMovement) {
    const data = new ProductAgingFilters();
    data.sku = row.sku;
    data.stockLocationCode = row.stockLocationCode;
    this.dialog.open(ProductAgingInfoModalComponent, { data });
  }

  private loadStockMovements() {
    this.stockBalanceService.findFilteredStockMovement().subscribe((resp: StockMovementResponse) => {
      this.header = resp.header;
      this.dataSource.data = resp.StockMovement;
      console.log(this.dataSource.data);
      console.log(this.dataSource.data[0]);
    });
  }
}
