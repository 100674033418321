import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { Status } from '@models/status-mode';
import { Shipment, ShipmentFilters } from '@models/shipment-model';
import { cachedArrayGet, GenericArrayCache } from '@shared/service-cached-array';

@Injectable()
export class ShipmentsService {
  shipmentsFilters: ShipmentFilters;

  shipmentsResultsNumber = new Subject<number>();

  private clientsCache = new GenericArrayCache<string>(this.http, this.converter);

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  getShipmentsByFilters(): Observable<Shipment[]> {
    const body = this.converter.fromObjtoJSON(this.shipmentsFilters);
    return this.http
      .post(this.environmentService.getRestEndpoint('shipments'), body, { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.shipmentsResultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
      )
      .pipe(
        map((resp: any[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, Shipment));
          } else {
            return [];
          }
        }),
      );
  }

  getShipmentStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('shipments') + '/status', { observe: 'response' })
      .pipe(map((resp: HttpResponse<any>) => resp.body))
      .pipe(
        map((resp: Status[]) => {
          if (!isNilty(resp)) {
            return resp.map((it) => this.converter.fromJSONtoObj(it, Status));
          } else {
            return null;
          }
        }),
      );
  }

  getClients(): Observable<string[]> {
    return cachedArrayGet<string>(this.clientsCache, this.environmentService.getRestEndpoint('shipments') + '/clients');
  }
}
