import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { SposService } from '../spos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpoItem } from '@models/spo-item-model';
import { SpoFilters } from '@models/spo-model';

@Component({
  selector: 'app-spo-items-list',
  templateUrl: './spo-items-list.component.html',
  styleUrls: ['./spo-items-list.component.css'],
})
export class SpoItemsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<SpoItem>;
  displayedColumns = [
    'orderId',
    'client',
    'owner',
    'supplier',
    'orderDate',
    'earliestDeliveryDate',
    'latestDeliveryDate',
    'sendingRouteType',
    'clientOrderItemId',
    'supplierOrderItemId',
    'supplierOrderItemStatus',
    'status',
    'ean',
    'altEan',
    'price',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'requestedValue',
    'confirmedValue',
    'orderedValue',
  ];

  resultsNumber: Observable<number>;
  initialFilters: SpoFilters;

  constructor(
    private sposService: SposService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.sposService.spoItemResultsNumber;

    if (isNilty(this.sposService.spoItemsFilters)) {
      this.sposService.spoItemsFilters = new SpoFilters();
    }

    this.route.queryParams.subscribe((params) => {
      if (params['clientApplicationId']) {
        this.sposService.spoItemsFilters.clientApplicationId = params['clientApplicationId'];
      }
    });

    this.initialFilters = this.sposService.spoItemsFilters;

    this.paginator.pageSize = this.sposService.spoItemsFilters.pageSize;
    this.paginator.pageIndex = this.sposService.spoItemsFilters.pageIndex;

    this.getSpoItems();
  }

  getSpoItems() {
    this.sposService.getSpoItemsByFilters().subscribe((resp: SpoItem[]) => {
      this.dataSource = new MatTableDataSource(resp);
    });
  }

  loadPage() {
    this.sposService.spoItemsFilters.pageSize = this.paginator.pageSize;
    this.sposService.spoItemsFilters.pageIndex = this.paginator.pageIndex;
    this.getSpoItems();
  }

  onSort() {
    this.sposService.spoItemsFilters.sortBy = this.sort.active;
    this.sposService.spoItemsFilters.sortDirection = this.sort.direction;
    this.getSpoItems();
  }

  applyFilters(filters: SpoFilters) {
    this.sposService.spoItemsFilters = filters;
    this.paginator.pageIndex = 0;
    this.getSpoItems();
  }

  openSpos() {
    this.router.navigate(['spos']);
  }
}
