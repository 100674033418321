import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProductAging, ProductAgingDetail, ProductAgingFilters } from '@models/product-aging-model';
import { Observable } from 'rxjs';
import { StockBalanceService } from '../stock-balance.service';
import { isNilty } from '@core/utils.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-product-aging',
  templateUrl: './product-aging.component.html',
  styleUrls: ['./product-aging.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductAgingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<ProductAging>;
  displayedColumns = ['sku', 'totalResidualQuantity', 'lastExitDate', 'stockLocation', 'expand'];

  incomesWithoutExitDisplayedColumns = ['operationId', 'quantity', 'date'];

  resultsNumber: Observable<number>;
  hasFilters = false;

  constructor(
    private stockBalanceService: StockBalanceService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.resultsNumber = this.stockBalanceService.productAgingResultNumber;
    if (isNilty(this.stockBalanceService.productAgingFilters)) {
      this.stockBalanceService.productAgingFilters = new ProductAgingFilters();
    }

    this.dataSource = new MatTableDataSource<ProductAging>([]);
    this.loadProducAging();
  }

  loadPage() {
    this.stockBalanceService.productAgingFilters.pageSize = this.paginator.pageSize;
    this.stockBalanceService.productAgingFilters.pageIndex = this.paginator.pageIndex;
    this.loadProducAging();
  }

  onSort() {
    this.stockBalanceService.productAgingFilters.sortBy = this.sort.active;
    this.stockBalanceService.productAgingFilters.sortDirection = this.sort.direction;
    this.loadProducAging();
  }

  applyFilters() {
    this.loadProducAging();
  }

  sumQuantity(incomesWithoutExit: ProductAgingDetail[]): number {
    if (incomesWithoutExit.length === 0) {
      return 0;
    }
    return incomesWithoutExit.reduce((sum, curretValue) => sum + curretValue.quantity, 0);
  }

  exportCSV() {
    this.stockBalanceService.exportProductAging().subscribe();
  }

  private loadProducAging() {
    this.stockBalanceService.findFilteredProductAging(this.stockBalanceService.productAgingFilters).subscribe((resp: ProductAging[]) => {
      resp.map((it) => {
        if (isNilty(it.lastExit)) {
          it.lastExit = new ProductAgingDetail();
        }
      });
      this.dataSource.data = resp;
    });
  }
}
