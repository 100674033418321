import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { Supplier } from '@models/supplier-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable({
  providedIn: 'root',
})
export class SpoaService {
  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  importSPOA(file: File, supplierConfigurationId: string, format: string, supplier: Supplier): Observable<void> {
    const body = new FormData();
    body.append('file', file);
    const path =
      this.environmentService.getRestEndpoint('spoa') + '/' + supplierConfigurationId + '?format=' + format + '&supplier=' + supplier.code;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.http.post(path, body).pipe(map(() => {}));
  }
}
