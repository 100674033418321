import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNilty } from '@core/utils.service';
import { OperationsService } from '@container/main-section/operations/operations.service';
import { Operation } from '@models/operation-model';
import { SetTrackingDataInput } from '@models/set-tracking-data-input-model';

@Component({
  selector: 'app-set-tracking-data-modal',
  templateUrl: './set-tracking-data-modal.component.html',
  styleUrls: ['./set-tracking-data-modal.component.css'],
})
export class SetTrackingDataModalComponent {
  trackingData = new SetTrackingDataInput();

  constructor(
    public dialogRef: MatDialogRef<SetTrackingDataModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Operation,
    private operationService: OperationsService,
  ) {
    this.trackingData.carrier = this.data?.data?.carrier;
    this.trackingData.trackingNumber = this.data?.data?.trackingNumber;
    this.trackingData.trackingUrl = this.data?.data?.trackingUrl;
  }

  save() {
    if (isNilty(this.trackingData.trackingNumber) || isNilty(this.trackingData.trackingUrl)) return;
    this.operationService.setTrackingData(this.data.id, this.trackingData).subscribe(() => this.dialogRef.close());
  }

  close() {
    this.dialogRef.close();
  }
}
