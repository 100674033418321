import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '@core/authorization.service';
import { Permissions } from '@models/permissions';
import { AuthenticationService } from '@security/authentication.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/environment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  currentMail = '';
  hasJimboReaderPermission: Observable<boolean>;
  hasJimboSupplierConfigurationPermission: Observable<boolean>;
  hasBridgetAdministrationPermission: Observable<boolean>;

  logoUrl: string = this.environmentService.logo;

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit() {
    this.authorizationService.getPermissions().subscribe(() => {
      this.currentMail = this.authorizationService.currentUserMail;
    });

    this.hasJimboReaderPermission = this.authorizationService.hasJimboPermission(Permissions.JimboReader);
    this.hasJimboSupplierConfigurationPermission = this.authorizationService.hasJimboPermission(Permissions.JimboSupplierConfiguration);
    this.hasBridgetAdministrationPermission = this.authorizationService.hasBridgetPermission(Permissions.BridgetAdministration);
  }

  refreshHomepage() {
    this.router.navigate(['/']);
  }

  logout() {
    this.authenticationService.logout();
  }
}
